.dial {
    margin: 0;
    padding: 2rem;
    /* width: 100%;
    height: 100%; */
    position: relative;
    
  }
/*   
  .dial .frame {
    width: 500px;
    height: 500px;
    position: relative;
    padding: 0px;
    margin: 0;
  } */
  
  .dial h6 {
    margin: 10px 0;
  }
  
  .dial .c .tooltip {
    visibility: hidden;
    position: absolute;
    z-index: 1;
  }
  
  .dial .c .tooltip {
    position: relative;
  }

  .dial-text-box {
    border:1px solid #eee;
    vertical-align: middle;
    height:100%;
    width:100%;
    margin: 5px auto;
  }
/*   
  .dial .c:hover {
    fill-opacity: 1;
  } */
  
  /* .dial .rightmin {
    margin: 2px 0;
    font-size: 8px;
    position: absolute;
    color: var(--grey);
    right: -14px;
    bottom: 0px;
  }
  
  .dial .rightmax {
    margin: 2px 0;
    font-size: 8px;
    position: absolute;
    color: var(--grey);
    right: -14px;
    top: 28px;
  }
  
  .dial .rightmed {
    margin: 2px 0;
    font-size: 8px;
    position: absolute;
    color: var(--grey);
    right: -14px;
    top: calc(50% + 7px);
  }
  
  .dial .leftmin {
    margin: 2px 0;
    font-size: 8px;
    position: absolute;
    color: var(--grey);
    left: -12px;
    bottom: 0px;
  }
  
  .dial .leftmax {
    margin: 2px 0;
    font-size: 8px;
    position: absolute;
    color: var(--grey);
    left: -30px;
    top: 28px;
  } */
  
  .dial .tooltip {
    visibility: hidden;
    background-color: white;
    border-radius: 5px;
    position: absolute;
    display: inline-block;
    /* top: 5px; */
    padding: 5px;
    margin: 0;
    min-width: 20px;
    box-shadow: 2px 2px 8px 0 #888;
    z-index: 999;
  }
  
  .dial .tooltip h6 {
    color: #000;
    font-size: 12px;
    padding: 0 0 4px 0;
    margin: 0;
  }
  
  .dial .tooltip p {
    color: #333;
    font-size: 10px;
    padding: 0;
    margin: 0;
  }
  
  .dial .tooltip p span {
    color: #aaa;
    font-weight: 500;
  }

  .dial svg rect.bar-c  {
    fill: var(--blue);
    fill-opacity: .6;
    stroke: var(--blue);
  }

  .dial svg rect.green {
    fill: var(--green);
    stroke: var(--green);
  }

  .dial svg rect.red {
    fill: var(--red);
    stroke: var(--red);
  }

  .dial svg line.green {
    stroke: var(--green);
  }

  .dial svg line.blue {
    stroke: var(--blue);
  }

  .dial svg line {
    stroke: var(--line-light);
  }

  .dial svg path {
    stroke: var(--blue);
    fill: transparent;
    stroke-width: 1;
  }

  .dial svg path.line0 {
    stroke: var(--blue);
    stroke-width: 2;
    fill: transparent;
  }

  .dial svg path.line1 {
    stroke: var(--green);
    fill: transparent;
  }

  .dial svg text {
    fill: var(--text-light);
    font-size: 10px;
  }

  .dial svg text.dial-title {
    fill: var(--blue);
    font-size: 16px;
  }

  .dial svg text.dial2-title {
    fill: var(--blue);
    font-size: 2px;
  }

  .dial svg text.dial-metric {
    fill: var(--text-med);
    font-size: 16px;
    font-weight: 600;
  }

  .dial svg text.dial2-metric {
    fill: var(--text-med);
    font-size: 1px;
    font-weight: 600;
  }
  