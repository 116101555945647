.drop-selector {
  margin:0;
  padding: 0;
  position: relative;
}

.drop-selector-frame {
  margin:0;
  /* border: 1px solid var(--text-light); */
  /* border-radius: 6px; */
  padding: 0;
  /* box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1); */
  position: relative;
}

.drop-selector-label {
  font-size: 14px;
  color: var(--text-dark);
  text-align: left ;
}

  .drop-selector.open {
    box-shadow: none;
  }

  .drop-selector .top-part {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* width:14em; */
  }

  .drop-selector .arrow-icon {
    padding: 0 1em;
  }
  
  .drop-selector .option {
    font-size: 14px;
    padding: 0;
    margin:0;
    width:100%;
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  
  .drop-selector .popup .option:hover {
    background-color: var(--back-lighter);
  }
  
  .drop-selector .popup .option:hover p {
    color: var(--blue);
  }
  
  .drop-selector .option img, .drop-selector .option p {
    padding: 0 0 0 1em;
    font-weight: 600;;
  }
  
  .drop-selector .option.selected p{
    color:var(--text-dark);
  }
  
  .drop-selector .popup {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
    margin:0;
    /* width:14em; */
    visibility: hidden;
    background-color: white;
    border-radius: 6px;
    border: 1px solid var(--text-light);
    position: absolute;
    overflow: visible;
    top:-1px;
    left:-1px;
    z-index: 300;
    /* box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1); */
}

.drop-selector .popup p {
  font-size: 14px;
  padding:1em calc(2em + 14px) 1em 1em;
  font-weight: 600;
  text-align:left;
  margin:0;
  /* width:172px; */
  color: var(--text-light);
}
  
.drop-selector.open .popup {
  visibility: visible;
}