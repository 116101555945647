.spinner {
  width: 100%;
  text-align: center;
}

.spinner svg {
  animation: 2s linear infinite svg-animation;
  max-width: 50px;
}

@keyframes svg-animation {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

.spinner circle {
  animation: 1.4s ease-in-out infinite both circle-animation;
  display: block;
  fill: transparent;
  stroke: var(--blue);
  stroke-linecap: round;
  stroke-dasharray: 142;
  stroke-dashoffset: 140;
  stroke-width: 2px;
  transform-origin: 50% 50%;
}

@keyframes circle-animation {
  0%,
  25% {
    stroke-dashoffset: 140;
    transform: rotate(0);
  }

  50%,
  75% {
    stroke-dashoffset: 38;
    transform: rotate(45deg);
  }

  100% {
    stroke-dashoffset: 140;
    transform: rotate(360deg);
  }
}
