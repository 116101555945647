.plot {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    position: relative;
  }
  
  .plot .frame {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 0px;
    margin: 0;
  }
  
  .plot h6 {
    margin: 10px 0;
  }
  
  .plot .c .tooltip {
    visibility: hidden;
    position: relative;
    z-index: 1;
  }
/*   
  .plot .c:hover {
    fill-opacity: 1;
  } */
  
  /* .plot .rightmin {
    margin: 2px 0;
    font-size: 8px;
    position: absolute;
    color: var(--grey);
    right: -14px;
    bottom: 0px;
  }
  
  .plot .rightmax {
    margin: 2px 0;
    font-size: 8px;
    position: absolute;
    color: var(--grey);
    right: -14px;
    top: 28px;
  }
  
  .plot .rightmed {
    margin: 2px 0;
    font-size: 8px;
    position: absolute;
    color: var(--grey);
    right: -14px;
    top: calc(50% + 7px);
  }
  
  .plot .leftmin {
    margin: 2px 0;
    font-size: 8px;
    position: absolute;
    color: var(--grey);
    left: -12px;
    bottom: 0px;
  }
  
  .plot .leftmax {
    margin: 2px 0;
    font-size: 8px;
    position: absolute;
    color: var(--grey);
    left: -30px;
    top: 28px;
  } */
  
  .plot .tooltip {
    visibility: hidden;
    background-color: white;
    border-radius: 5px;
    position: absolute;
    display: inline-block;
    /* top: 5px; */
    padding: 10px;
    margin: 0;
    min-width: 20px;
    box-shadow: 2px 2px 8px 0 #888;
    z-index: 999;
  }
  
  .plot .tooltip h6 {
    color: #000;
    font-size: 12px;
    padding: 0 0 4px 0;
    margin: 0;
  }
  
  .plot .tooltip p {
    color: #333;
    font-size: 10px;
    padding: 0;
    margin: 0;
  }
  
  .plot .tooltip p span {
    color: #aaa;
    font-weight: 500;
  }

  .plot svg rect.bar-c  {
    fill: var(--blue);
    fill-opacity: .6;
    stroke: var(--blue);
  }

  .plot svg rect.green {
    fill: var(--green);
    stroke: var(--green);
  }

  .plot svg rect.overlay {
    fill: black;
    fill-opacity: 0.05;
    stroke-opacity: 0;
  }

  .plot svg rect.red {
    fill: var(--red);
    stroke: var(--red);
  }

  .plot svg line.green {
    stroke: var(--green);
  }

  .plot svg line.blue {
    stroke: var(--blue);
  }

  .plot svg line {
    stroke: var(--line-light);
  }

  .plot svg path.line0 {
    stroke: var(--blue);
    stroke-width: 2;
    fill: transparent;
  }

  .plot svg path.line1 {
    stroke: var(--green);
    fill: transparent;
  }

  .plot svg text {
    fill: var(--text-med);
    font-size: 10px;
  }

  .plot svg text.title {
    fill: black;
    font-size: 14px;
  }

  .plot svg text.title-small {
    fill: var(--text-dark);
    font-size: 12px;
  }
  