.home-page {
    margin: 0;
    padding:0;
    border:none;
    flex:1;
}

.home-page .top-background {
    background-image: linear-gradient(to right, #1F88FB , #29C9FE);
    clip-path: polygon(
        0 0,
        100% 0,
        100% calc(100% - 6vw),
        0 100%
    );
    position: absolute;
    margin:0;
    padding:0;
    top:60px;
    height:450px;
    width:100%;
    z-index: -5;
}

.home-page .top {
    margin:0;
    padding:2rem 0;
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: transparent;
}

.home-page .main-copy {
    padding:0;
    max-width: 400px;
    min-height: 400px;
}

.home-page .main-copy h1 {
    margin:0;
    padding:2rem 0;
    font-weight: 400;
    color:white;
    font-size:2.4rem;
}

.home-page .main-copy h4 {
    margin:0;
    padding:0;
    font-weight: 400;
    color:white;
    line-height: 1.4rem;
}

.home-page .main-copy h1 span{
    margin:0;
    padding:0;
    color:#8CCAE6;
    font-weight: 600;
}


.home-page .icon-section {
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    padding:1rem;
}

.home-page .icon-item {
    text-align: center;
}

.home-page .line1 {
    text-align: center;
    line-height: 2px;
}

.home-page .middle {
    background-image: linear-gradient(to right, #1F88FB , #29C9FE);
    text-align: center;
}

.home-page .middle-row {
    display:flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 4rem 1rem;
}

.home-page .middle-row .text-area{
    max-width:300px;
    color:white;
    text-align: left;
}

.home-page .middle-row .text-area h2 {
    color:white;
    font-size:1.7rem;
    text-align: left;
    margin: 1rem 0;
}

.home-page .middle-row .text-area p {
    line-height: 1.4rem;
}



@media only screen and (max-width: 630px) {
    .home-page .top img, .home-page .middle-row img {
        display: none;
    }
    
  }






.home-page .image-link img {
    border: 1px solid var(--line-light);
    height: 180px;
    box-shadow: 2px 2px 8px 0 #ddd;
}

.home-page .image-link {
    margin:30px;
}

.home-page a:hover .image-link img {
    box-shadow: 2px 2px 8px 0 #888;
}

.home-page .image-link p {
    color: var(--blue)
}