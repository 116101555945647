.left, .right {
  border:1px solid #666;
  width:100%;
  height:30px;
  text-align: center;
  vertical-align: middle;
  user-select: none; /* supported by Chrome and Opera */
   -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
   -moz-user-select: none; /* Firefox */
   -ms-user-select: none;
}

/* .left {
  border-right: none;
}

.right {
  border-left: none;
} */

.drag-border {
  width:1px;
  height:30px;
  border:1px solid #aaa;
  z-index: 10;
}

.drag-border:hover {
  background-color: #666;
  cursor:ew-resize;
  border: 2px solid #666;
}

.row {
  position:relative;
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: calc(100%);
}