

.grid .topline {
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 15px 40px 40px 40px;
}

@media only screen and (max-width: 600px) {
  .grid .topline {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
  }
  .grid .topline .drop-selector {
      margin: 0 0 30px 0;
  }
}

.grid .selectors .selector {
  margin-top:16px;
}

.grid {
  margin:5px 30px;
}

.settings {
  background-color: var(--back-lighter);
  padding:30px;
  position: relative;
  overflow:visible;
}

.settings.collapsed {
  padding:20px;
}

.settings .collapse {
  position:absolute;
  padding:10px;
  top:0;
  right:0
}

.sort-selector {
  background-color: white;
  display: relative;
  padding:0 10px;
  border-radius: 30px;
  border: 1px solid var(--back-light);
  overflow: hidden;
}

.sort-selector .top-part {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding:0 10px;
  height:40px;
  min-width:120px;
  color: black;
  line-height: 40px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 700;
}

.sort-selector .top-part img {
  padding:0 20px 0 0;
  margin:0;
}

.sort-selector .popup {
    visibility: hidden;
    text-align: center;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #ccc;
    background-color: white;
    position: absolute;
    overflow: visible;
    z-index: 300;
    text-align:left;
}

.sort-selector:hover .popup {
  visibility: visible;
}

.sort-selector .dimension:hover {
  background-color: var(--back-light);
}

.item-grid {
  padding:0px;
  background-color: white;
  border-radius: 20px;
  text-align:left;
  flex-grow:5;
  line-height: 0;
}

.item {
  height:40px;
  width:40px;
  margin:0px;
  margin-left:1px;
  padding:0px;
  line-height: 40px;
  font-size: 10px;
  text-align: center;
  display:inline-block;
  position: relative;
}

.item .popup-note {
  visibility: hidden;
    text-align: center;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #ccc;
    background-color: white;
    position: absolute;
    z-index: 100;
    width: 140px;
    top: -40%;
    overflow: visible;
    z-index: 300;
    left: -165px;
    text-align:left;
    margin-top: -100%; 
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 16px 0 rgba(0, 0, 0, 0.2);
}

.item .popup-note p {
  color: grey;
  margin:0;
  padding:0;
  line-height: 1.4;
}

.item .popup-note p span {
  color:var(--blue);
}


.item .popup-note::after {
    content: " ";
    position: absolute;
    top: 50%; 
    left: 100%;
    margin-left: -17px;
    border-width: 17px;
    border-style: solid;
    border-color: white transparent transparent transparent;
}

.item:hover .popup-note {
  visibility: visible;
}

.timeline-chart {
  padding:50px;
  background-color: white;
  border-radius: 20px;
  text-align:left;
  flex-grow:5;
  line-height: 0;
}

.chart-line {
  padding:0;
  margin:0;
  height:4px;
  display:flex;
  flex-direction: row;
  align-items: flex-start;
}

.chart-line p.bar-tick {
  visibility: hidden;
  display:inline-block;
  width:110px;
  font-size:9px;
  color: var(--text-light);
  text-align: right;
  padding: 0 8px 0 0;
  margin: 0px;
  height:4px;
  line-height: 4px;
}

.chart-line p.bar-value {
  visibility: hidden;
  display:inline-block;
  font-size:10px;
  font-weight: 700;
  color: var(--text-dark);
  text-align: left;
  padding: 0 0 0 8px;
  margin: 0px;
  height:4px;
  line-height: 4px;
}

.horizontal-bar {
  display:inline-block;
  background-color: var(--purple);
  height:2px;
  width:5%;
  margin:1px 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.dimension {
  color: var(--text-light);
  font-size:11px;
  font-weight: 700;
  padding:10px;
  margin:0;
}

.dimension.selected {
  color: black;
} 
