*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  --select-border: #ddd;
  --select-focus: #16B1FD;
  --select-arrow: var(--select-border);

  --green: #46CEAC;
  --green-dark: #36BA9C;
  --green-light: #61DDBC;
  --green-darker: #2E9F85;
  --green-lighter: #CFF5EB;

  --blue: #16B1FD;
  --blue-dark: #3BADD9;
  --blue-light:#67D4F2;
  --blue-lighter: #B0ECFC;
  --blue-darker: #009BD9;
  --blue-darkerer: #005B99;
 
  --red: #EB5564;
  --red-dark: #D94552;
  --red-light: #F76D82;
  --red-lighter: #FFB4C0;
  --red-darker: #B93541;

  --tan-darker: #EAA61B;

  --back-light: #e7edf2;
  --back-med: #A4CEE5;
  --back-lighter: #F0F4F7;
  --line-light: #ddd;
  --line-dark: #838297;
  --line-med: #ccccd8;
  --text-light: #ADBDCC;
  --text-med: #838297;
  --text-dark: #4B5F73;
  --purple: #6341f6;
}


body {
  background-color:white;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  color: var(--text-med);
  margin:0;
}

/* the app contains the header, the page component, and the footer in a flex column so it fills the viewport */
.App {
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

img, svg, svg.icon {
  overflow: hidden;
}

/* svg {
  overflow: visible;
}

svg.icon {
  overflow: hidden;
} */


h1 {
  font-size:2rem;
  padding:1rem;
  font-weight: 300;
}

h1, h2, h3, h4, h5 {
  color: black;
  margin:1rem;
  font-weight: 400;
}

h2 {
  font-size: 1.6rem;
  font-weight: 300;
}

h6 {
  color: var(--text-light);
  text-transform: uppercase ;
}

hr {
  border:none;
  border-bottom: 1px solid #ddd;
  margin: 20px 0;
}

a {
  color: var(--blue);
  text-decoration: none;
}

a.link-small {
  font-size: 12px;
}

a.is-active, a:hover {
  color: var(--text-dark);
  text-decoration: none;
}

/* .word {
  font-size: 20px;
  color: var(--blue);
  font-weight: 600;
  padding: 0  1rem;
} */

.dictionary {
  max-width: 800px;
}

.text-box {
  max-width: 600px;
}

.dictionary td{
  vertical-align: top;
}

nav {
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  /* border-bottom: 1px solid #e4e8eb; */
  padding:20px 2rem;
  margin: 0;
  /* background-color: white; */
  background-image: linear-gradient(to right, #1F88FB , #29C9FE);
}

@media only screen and (max-width: 600px) {
    nav {
      padding:20px 1rem;
    }
  }

nav .logo{
  padding:0;
  margin:0;
  line-height: 0;
}

nav a {
  margin:0;
  padding:0 1rem 0 0;
  text-decoration: none;
  color: white;
  font-weight: 400;
}

nav a.is-active, nav a:hover {
  color: var(--blue-darker);
}

.footer {
  background-color: black;
  padding:0;
  margin:0;
}

.footer .strip {
  background-color: white;
  padding:5px 20px;
  margin:0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.footer .strip p,.footer .strip a {
  padding:0px;
  margin:0;
  font-size:0.7rem;
  line-height: 0;
  text-decoration: none;
}

.footer .main {
  background-color: black;
  padding:20px 2rem;
  margin:0;
  line-height: 0;
}

@media only screen and (max-width: 600px) {
    .footer .main {
      padding:20px 1rem;
    }
  }

.icon svg polygon {
  fill: var(--back-light);
  stroke: var(--back-light);
}

.highlight {
  /* \this makes is so the touchmove event is not lost when the highlighted item moves */
  pointer-events: none;
}


.selector {
  text-align: right;
  padding:10px 0;
}

.selector.left {
  text-align: left;
}

.selector .selections {
  display:flex;
  flex-direction: row;
  justify-content: flex-start;
  padding:0;
  margin:0;
}

.selector h4 {
  font-size:10px;
  color: var(--text-med);
  text-transform: uppercase;
  padding:0 0 6px 0;
  margin:0;
  font-weight: 400;
}

.selector .selections p {
  font-size: 14px;
  padding: 0 0 0 1.5rem;
  margin:0;
  color: var(--text-dark);
  font-weight:500;
}

.selector.left .selections p {
  padding: 0 1.5rem 0 0;
}

.selector .selections p.active, .selector .selections p:hover {
  color: var(--blue)
}

.border {
  border: 1px solid #ccc;
  padding:20px;
}

@keyframes fade-out {
  0% {display:block;opacity: 1;}
  100% {display:none;opacity: 0;}
}

@keyframes fade-in {
  0% {fill: var(--green-light); opacity: 0;}
  80% {fill: var(--green-light); opacity: 1;}
  100% {fill: var(--blue); opacity: 1;}
}

.rmdots {
  fill: var(--red);
  /* animation-name: fade-out;
  animation-duration: .5s;
  animation-fill-mode: forwards; */
}

.adddots {
  fill: var(--green);
  /* animation-name: fade-in;
  animation-duration: .5s;
  animation-fill-mode: forwards; */
}
/* 
.chart {
  margin:5px 30px;
  text-align: center;
  background-color: white;
  padding:0px;
  border-radius: 20px;
  overflow: hidden;
  display:flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: normal;
}

.settings {
  background-color: var(--back-lighter);
  padding:30px;
  position: relative;
  overflow:visible;
}

.settings.collapsed {
  padding:20px;
}

.settings .collapse {
  position:absolute;
  padding:10px;
  top:0;
  right:0
}

.sort-selector {
  background-color: white;
  display: relative;
  padding:0 10px;
  border-radius: 30px;
  border: 1px solid var(--back-light);
  overflow: hidden;
}

.sort-selector .top-part {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding:0 10px;
  height:40px;
  min-width:120px;
  color: black;
  line-height: 40px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 700;
}

.sort-selector .top-part img {
  padding:0 20px 0 0;
  margin:0;
}

.sort-selector .popup {
    visibility: hidden;
    text-align: center;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #ccc;
    background-color: white;
    position: absolute;
    overflow: visible;
    z-index: 300;
    text-align:left;
}

.sort-selector:hover .popup {
  visibility: visible;
}

.sort-selector .dimension:hover {
  background-color: var(--back-light);
}

.item-grid {
  padding:50px;
  background-color: white;
  border-radius: 20px;
  text-align:left;
  flex-grow:5;
  line-height: 0;
}

.item {
  height:40px;
  width:40px;
  margin:1px;
  padding:0px;
  line-height: 40px;
  font-size: 10px;
  text-align: center;
  display:inline-block;
  position: relative;
}

.item .popup-note {
  visibility: hidden;
    text-align: center;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #ccc;
    background-color: white;
    position: absolute;
    z-index: 100;
    width: 140px;
    top: -40%;
    overflow: visible;
    z-index: 300;
    left: -165px;
    text-align:left;
    margin-top: -100%; 
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 16px 0 rgba(0, 0, 0, 0.2);
}

.item .popup-note p {
  color: grey;
  margin:0;
  padding:0;
  line-height: 1.4;
}

.item .popup-note p span {
  color:var(--blue);
}


.item .popup-note::after {
    content: " ";
    position: absolute;
    top: 50%; 
    left: 100%;
    margin-left: -17px;
    border-width: 17px;
    border-style: solid;
    border-color: white transparent transparent transparent;
}

.item:hover .popup-note {
  visibility: visible;
}

.timeline-chart {
  padding:50px;
  background-color: white;
  border-radius: 20px;
  text-align:left;
  flex-grow:5;
  line-height: 0;
}

.chart-line {
  padding:0;
  margin:0;
  height:4px;
  display:flex;
  flex-direction: row;
  align-items: flex-start;
}

.chart-line p.bar-tick {
  visibility: hidden;
  display:inline-block;
  width:110px;
  font-size:9px;
  color: var(--text-light);
  text-align: right;
  padding: 0 8px 0 0;
  margin: 0px;
  height:4px;
  line-height: 4px;
}

.chart-line p.bar-value {
  visibility: hidden;
  display:inline-block;
  font-size:10px;
  font-weight: 700;
  color: var(--text-dark);
  text-align: left;
  padding: 0 0 0 8px;
  margin: 0px;
  height:4px;
  line-height: 4px;
}

.horizontal-bar {
  display:inline-block;
  background-color: var(--purple);
  height:2px;
  width:5%;
  margin:1px 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.dimension {
  color: var(--text-light);
  text-transform: uppercase;
  font-size:11px;
  font-weight: 700;
  padding:10px;
  margin:0;
}

.dimension.selected {
  color: black;
} */

.center {
  text-align: center;
}



form {
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 20px auto;
  /* border: 1px solid var(--line-light); */
  width:298px;
  padding:20px;
  border-radius: 10px;
  background-color: white;
  text-align: center;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 16px 0 rgba(0, 0, 0, 0.2); */
}

form p {
  color: var(--text-med)
}

input {
  padding:15px 20px;
  margin: 10px 0;
  /* width:256px; */
  border-radius: 10px;
  font-size: 16px;
  border: 1px solid var(--line-light);
}

input:focus { 
  border-color: var(--blue);
  outline: 0;
}

input[type="file"] {
  border:none;
}

.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.inputfile + label {
  font-size: 16px;
  font-weight: 500;
  color: var(--text-med);
  border: 1px solid var(--line-light);
  border-radius: 10px;
  width: calc(100% - 42px);
  display: inline-block;
  cursor: pointer; 
  padding:15px 20px;
  background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
}

.inputfile:focus + label,
.inputfile + label:hover {
  background-color: var(--back-lighter);
}

.inputfile:focus + label {
	outline: 1px dotted #000;
	outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label * {
	pointer-events: none;
}

button {
  background-color: white;
  border-radius: 40px;
  padding:1rem 2rem;
  font-size: 14px;
  cursor: pointer;
  color: var(--text-med);
  border: 1px solid var(--text-med);
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 16px 0 rgba(0, 0, 0, 0.2); */
    /* display:flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center; */
    background-color: white;
    border-radius: 40px;
    padding:10px 30px;
    font-size: 14px;
    font-weight: 500;;
    color: var(--blue);
    text-transform: uppercase;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 16px 0 rgba(0, 0, 0, 0.2); */
    text-decoration: none;
}

button:hover{
  color: var(--blue-darkerer);
  /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 16px 0 rgba(0, 0, 0, 0.2); */
}

button img {
  padding-right: 1rem;

}


.error {
  color: var(--red);
  margin:0;
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
}
.loader {
  color:var(--blue);
  font-size: 11px;
  text-indent: -99999em;
  margin: 55px auto;
  position: relative;
  width: 5em;
  height: 5em;
  box-shadow: inset 0 0 0 0.3em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader:before,
.loader:after {
  position: absolute;
  content: '';
}
.loader:before {
  width: 2.6em;
  height: 5.2em;
  background:white;
  border-radius: 5.2em 0 0 5.2em;
  top: -0.1em;
  left: -0.1em;
  -webkit-transform-origin: 2.6em 2.6em;
  transform-origin: 2.6em 2.6em;
  -webkit-animation: load2 1s infinite ease 1.5s;
  animation: load2 1s infinite ease 1.5s;
}
.loader:after {
  width: 2.6em;
  height: 5.2em;
  background: white;
  border-radius: 0 5.2em 5.2em 0;
  top: -0.1em;
  left: 2.4em;
  -webkit-transform-origin: 0.1em 2.6em;
  transform-origin: 0.1em 2.6em;
  -webkit-animation: load2 1s infinite ease;
  animation: load2 1s infinite ease;
}

@-webkit-keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

::-webkit-input-placeholder { /* Edge */
  color: var(--text-light);
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: var(--text-light);
}

::placeholder {
  color: var(--text-light);
}

table {
  border-collapse: collapse;
  margin: 25px 0;
  min-width: 300px;
  width:100%;
}

table th, table td {
  padding: .8rem 0;
  text-align: left;
}

table thead {
  border-bottom: 1px solid var(--line-light);
}


.small-white-button {
  font-size:14px;
  font-weight: 300;;
  background-color: white;
  border: 1px solid var(--line-light);
  color: var(--text-med);
  padding:10px 15px;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.svg-value {
  font-size: 16px;
  fill: black;
  font-weight: 500;
}

.svg-value {
  font-size: 24px;
  fill: black;
  font-weight: 500;
}

.svg-label {
  font-size:9px;
  fill: var(--text-med);
  /* text-transform: uppercase; */
  font-weight: 400;
}

.svg-label-darker {
  font-size:9px;
  fill: black;
}

.svg-label2 {
  font-size:11px;
  fill: var(--text-med);
  /* text-transform: uppercase; */
  font-weight: 400;
}

.svg-label2-darker {
  font-size:11px;
  fill: black;
  font-weight: 600;
}

.svg-label3 {
  font-size:16px;
  fill: var(--text-med);
  /* text-transform: uppercase; */
  font-weight: 400;
}

.svg-label3-dark {
  font-size:16px;
  fill: var(--text-dark);
  /* text-transform: uppercase; */
  font-weight: 400;
}

.svg-label3-darker {
  font-size:16px;
  fill: black;
  font-weight: 500;
}

.svg-head {
  font-size:14px;
  color: var(--text-med);
  /* text-transform: uppercase; */
  font-weight: 500;
  /* letter-spacing: .08rem; */
}

.panel {
  text-align: center;
  /* margin: 1rem 0 1rem 1rem; */
  /* border: 1px solid var(--line-light); */
  /* border-radius: 6px; */
  /* box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1); */
  background-color: white;
  padding: 1rem;
  width:100%;
  /* flex-grow: 1; */
}

.topline {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin:0;
}

@media only screen and (max-width: 600px) {
    .panel .topline {
        flex-direction: column;
        justify-content: flex-start;
        margin:0;
    }
    .panel .topline .drop-selector {
        margin: 0 0 30px 0;
    }
  }

  .page-body {
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: calc(100vh - 156px);
    margin: 1rem 2rem;
  }

  @media only screen and (max-width: 600px) {
    .page-body {
          margin:1rem 1rem;
      }
    }

.row {
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: calc(100%);
}

.column {
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: calc(100%);
}

.around {
  justify-content: space-around;
  width: 100%;
}

button.small {
  padding:10px 5px 0 5px;
  font-size: 11px;
  font-weight: 600;
  border:none;
  color: var(--blue-dark);
}

button.small:hover {
  color: var(--blue-darkerer);
}


.title-with-caption{
  text-align: center;
  margin: 0;
}

.title-with-caption h4{
  font-size: 16px;
  margin:0;
  padding:0;
}

.title-with-caption p{
  font-size: 10px;
  margin:0;
  padding-bottom:5px;
}

.tooltip .title {
  font-size: 12px;
  color: var(--blue);
  margin:0;
}

.tooltip .label {
  font-size: 10px;
  font-weight: 500;
  color: var(--blue);
  fill: var(--blue);
  margin:0;
}

.tooltip .value {
  font-size: 10px;
  color: var(--text-dark);
  margin:0;
  text-align: right;
}

.message {
  border: 1px solid var(--blue);
  font-weight:400;
  color: var(--text-dark);
  padding:10px;
  margin:0px;
}

.message span{
  font-weight:500;
  color: var(--blue);
}

select::-ms-expand {
  display: none;
}

select {
  /* // A reset of styles, including removing the default dropdown arrow */
  appearance: none;
  /* // Additional resets for further consistency */
  background-color: transparent;
  border: none;
  padding: 1rem 1.25rem;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  outline:none;
}
.select {
  width: 100%;
  min-width: 15ch;
  max-width: 30ch;
  border: 1px solid var(--select-border);
  border-radius: 10px;
  padding: 0;
  font-size: 1rem;
  cursor: pointer;
  line-height: 1.1;
  background-color: #fff;
  background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
  display: grid;
  grid-template-areas: "select";
  align-items: center;
}
select::after {
  content: "";
  width: 0.8em;
  height: 0.5em;
  background-color: var(--select-arrow);
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  justify-self: end;
}

select,
select::after {
  grid-area: select;
}



.stat p {
  font-size: 12px;
  font-weight: 400;
  color: #000;
  margin:0;
  padding:1px;
  text-align: center;
}

/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) { 
  .stat p {
      font-size: 10px;
  }
}

.stat p.stat-name {
  font-size: 7px;
  font-weight: 400;
  color: var(--text-med);
}

.tight {
  padding:0;
  margin:0;
}

.pointer {
  cursor: pointer; 
}

/* svg line, svg path {
  stroke: var(--blue)
} */

svg line .gray, svg path.gray {
  stroke: var(--line-light)
}

svg.icon line, svg.icon rect, svg.icon circle, svg.icon path{
  stroke: var(--text-med);
  fill:none;
}

svg.icon .fill{
  fill: var(--text-med)
}

svg.icon:hover line, svg.icon:hover rect, svg.icon:hover circle, svg.icon:hover path {
  stroke: black
}

svg.icon.active line, svg.icon.active rect, svg.icon.active circle, svg.icon.active path {
  stroke:  var(--blue);
}

svg.icon {
  stroke-linecap: round;
}

svg.icon:hover .fill, svg.icon.active .fill{
  fill: var(--blue)
}

/* svg line, svg path {
  stroke: var(--blue)
} */

svg line.dark, svg path.dark {
  stroke: var(--line-dark)
}

svg line.darker, svg path.darker {
  stroke: black
}

.blue {
  color: var(--blue)
}

.fade {
  /* visibility: hidden; */
  opacity: 0.3;
  transition: opacity 2s linear;
}

.tab {
  border-radius: 10px 10px 0 0;
  border: 1px solid white;
  border-bottom: 1px solid white;
}

.tab:hover {
  border-color:var(--line-med);
  border-bottom-color: white;
}

.tab-selected {
  border-radius: 10px 10px 0 0;
  border: 1px solid var(--line-med);
  border-bottom: 1px solid white;
  z-index: 10;
}

.details {
  max-height: 0;
  overflow: hidden;
  padding:0;
  margin:0;
}

.details.expanded {
  max-height: 500px;
  padding:1rem;
  position:relative;
  top:-1px;
  border: 1px solid var(--line-med);
  transition: max-height 500ms ease-out;
}

.plot {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  position: relative;
}