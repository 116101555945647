.checkbox {
  width: 100%;
  margin: 5px auto;
  position: relative;
  display: block;
  text-align: left;
}

.checkbox label {
  position: relative;
  min-height: 34px;
  display: block;
  font-size: 14px;
  padding-left: 40px;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer;
  color: var(--text-med);
}
.checkbox label span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.checkbox label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  margin: 4px;
  width: 18px;
  height: 18px;
  transition: transform 0.28s ease;
  border-radius: 3px;
  border: 2px solid var(--line-light);
}

.checkbox label:after {
  content: '';
  display: block;
  width: 10px;
  height: 5px;
  border-bottom: 2px solid var(--blue);
  border-left: 2px solid var(--blue);
  transform: rotate(-45deg) scale(0);
  transition: transform ease 0.25s;
  position: absolute;
  top: 10px;
  left: 9px;
}
.checkbox input[type="checkbox"] {
  width: auto;
  opacity: 0.00000001;
  position: absolute;
  left: 0;
  margin-left: -20px;
}
.checkbox input[type="checkbox"]:checked ~ label:before {
  border: 2px solid var(--blue);
}
.checkbox input[type="checkbox"]:checked ~ label:after {
  transform: rotate(-45deg) scale(1);
}
.checkbox input[type="checkbox"]:focus + label::before {
  outline: 0;
}